/**
 * Internal dependencies
 */
import SwiperCarousel from "../../js/components/swiper-carousel";

document.addEventListener("DOMContentLoaded", function () {
	const carousels = document.getElementsByClassName("js-bsab4s-carousel");
	if (carousels.length > 0) {
		for (var i = 0; i < carousels.length; i++) {
			(function (i) {
				let swiperParams = carousels[i].getAttribute("data-swiper-params")
						? JSON.parse(carousels[i].getAttribute("data-swiper-params"))
						: false,
					alignControls = carousels[i].getAttribute("data-align-controls")
						? carousels[i].getAttribute("data-align-controls")
						: false,
					navigationPosition = carousels[i].getAttribute(
						"data-navigation-position"
					)
						? carousels[i].getAttribute("data-navigation-position")
						: "above";

				let swiperCarouselOptions = {
					swiperParams: swiperParams,
					navigationPosition: navigationPosition,
					alignControls: alignControls,
				};

				new SwiperCarousel(carousels[i], swiperCarouselOptions);
			})(i);
		}
	}
});
