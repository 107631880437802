export const toKebabCase = (str) => {
	if (!str) {
		return "";
	}

	return (
		str
			?.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
			)
			?.join("-")
			?.toLowerCase() ?? ""
	);
};

export function extendProps() {
	// Variables
	var extended = {};
	var deep = false;
	var i = 0;
	var length = arguments.length;
	// Check if a deep merge
	if (Object.prototype.toString.call(arguments[0]) === "[object Boolean]") {
		deep = arguments[0];
		i++;
	}
	// Merge the object into the extended object
	var merge = function (obj) {
		for (var prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				// If deep merge and property is an object, merge properties
				if (
					deep &&
					Object.prototype.toString.call(obj[prop]) === "[object Object]"
				) {
					extended[prop] = extend(true, extended[prop], obj[prop]);
				} else {
					extended[prop] = obj[prop];
				}
			}
		}
	};
	// Loop through each object and conduct a merge
	for (; i < length; i++) {
		var obj = arguments[i];
		merge(obj);
	}
	return extended;
}
