/**
 * Internal dependencies
 */
import { extendProps } from "../../utils";

import Swiper from "swiper";
import {
	Navigation,
	Pagination,
	Autoplay,
	A11y,
	Grid,
	EffectCreative,
	EffectCoverflow,
} from "swiper/modules";

export default function SwiperCarousel(element, opts) {
	this.options = extendProps(SwiperCarousel.defaults, opts);
	this.swiperParams = extendProps(
		SwiperCarousel.defaults.swiperParams,
		opts.swiperParams
	);
	this.element = element;
	this.controls = element.querySelectorAll(".js-bsab4s-swiper__control");
	this.pagination = element.querySelector(".js-bsab4s-swiper__pagination");
	this.swiperInstance = [];
	initSwiperCarouselLayout(this);
	initSwiperCarouselEvents(this);
	initSwiperCarousel(this);
}

function initSwiperCarouselLayout(carousel) {
	initSwiperCarouselControls(carousel);
	initSwiperCarouselPagination(carousel);
	alignControls(carousel); // check if controls need to be aligned to a different element
}

function initSwiperCarouselControls(carousel) {
	if (carousel.controls.length < 1) return;
	if (!carousel.controls[0]) return;
	carousel.swiperParams.navigation = {
		...carousel.swiperParams?.navigation,
		prevEl: carousel.controls[0],
	};
	if (!carousel.controls[1]) return;
	carousel.swiperParams.navigation = {
		...carousel.swiperParams?.navigation,
		nextEl: carousel.controls[1],
	};
}

function initSwiperCarouselPagination(carousel) {
	if (carousel.pagination) return;
	carousel.swiperParams.pagination = {
		...carousel.swiperParams?.pagination,
		el: carousel.pagination,
	};
}

function initSwiperCarouselEvents(carousel) {
	// reset on resize
	window.addEventListener("resize", function (event) {
		clearTimeout(carousel.resizeId);
		carousel.resizeId = setTimeout(function () {
			alignControls(carousel);
		}, 250);
	});
}

function alignControls(carousel) {
	if (
		carousel.controls.length < 1 ||
		!carousel.options.alignControls ||
		carousel.options.navigationPosition !== "above"
	)
		return;
	if (!carousel.controlsAlignEl) {
		carousel.controlsAlignEl = carousel.element.querySelector(
			carousel.options.alignControls
		);
	}
	if (!carousel.controlsAlignEl) return;
	var offsetValue =
		carousel.element.offsetHeight - carousel.controlsAlignEl.offsetHeight;
	for (var i = 0; i < carousel.controls.length; i++) {
		carousel.controls[i].style.marginBottom = offsetValue + "px";
	}
}

function initSwiperCarousel(carousel) {
	carousel.swiperInstance = new Swiper(carousel.element, carousel.swiperParams);
}

SwiperCarousel.defaults = {
	alignControls: false,
	navigationPosition: false,
	swiperParams: {
		modules: [
			Autoplay,
			A11y,
			EffectCreative,
			EffectCoverflow,
			Navigation,
			Pagination,
		],
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		a11y: {
			prevSlideMessage: "Previous slide",
			nextSlideMessage: "Next slide",
		},
	},
};

window.BsaWebBlocksSwiperCarousel = SwiperCarousel;
